<template>
  <div v-loading="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title_fw">合同、回款计划、开票及回款统计</span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :type="'Histogram'"
            :introductionType="'staffId'"
            :yearIsShow="true"
            :list="dictData.yearList"
            :twoValue="sectionInfo.contractAmountPrincipal"
            v-model="sectionInfo.contractAmountYear"
            @change="onHistogramChange"
            @changeData="onHistogramChangeData"
        /></span>
        <span class="title_right" v-if="isBoss"
          ><SearchUserDate
            :clearable="true"
            :filterable="true"
            :type="'Histogram'"
            :introductionType="'staffId'"
            :title="titlePrincipal"
            :list="userList"
            :yearValue="sectionInfo.contractAmountYear"
            v-model="sectionInfo.contractAmountPrincipal"
            @change="onHistogramChange"
            @changeData="onHistogramChangeData"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="isDialog = true"
          >数据来源说明</el-button
        >
      </div>
      <Histogram :contractAmountList="contractAmountList" />

      <el-table height="240px" border row-key="id" :data="contractAmountData">
        <el-table-column
          prop="contractAmountName"
          label="金额类型"
          align="center"
          fixed="left"
          min-width="120"
        ></el-table-column>
        <el-table-column prop="januaryAmount" min-width="120" label="一月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.januaryAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="februaryAmount" min-width="120" label="二月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.februaryAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="marchAmount" min-width="120" label="三月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.marchAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="aprilAmount" min-width="120" label="四月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.aprilAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="mayAmount" min-width="120" label="五月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.mayAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="juneAmount" min-width="120" label="六月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.juneAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="julyAmount" min-width="120" label="七月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.julyAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="augustAmount" min-width="120" label="八月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.augustAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="septemberAmount" min-width="120" label="九月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.septemberAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="octoberAmount" min-width="120" label="十月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.octoberAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="novemberAmount" min-width="120" label="十一月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.novemberAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="decemberAmount" min-width="120" label="十二月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.decemberAmount | applyAmount }}
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img src="@/assets/商务统计-合同回款统计.png" alt="数据来源说明" width="100%" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    Histogram: () => import('./histogram.vue'),
    SearchUserDate: () => import('../searchUserDate.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      userList: [], // 用户数组
      dictData: {
        yearList: [],
      },
      contractAmountList: [], // 合同回款计划
      contractAmountData: [],
      sectionInfo: {
        // 条件筛选// 年份
        contractAmountYear: null,
        contractAmountPrincipal: '', // 负责人
      },
      isBoss: false, // 是否BOSS
      businessNameList: [], // 商务名称
      titleYear: '统计时间',
      titlePrincipal: '商务负责人',
      isDialog: false,
    }
  },
  provide() {
    return {}
  },
  computed: {},
  created() {
    this.getType()
  },
  filters: {
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  watch: {},
  mounted() {},
  methods: {
    init(val) {
      this.loading = true
      this.$api.statistics
        .queryContractPaymentSummary(`date=${val}`)

        .then(res => {
          const { contractPaymentStatisticsVo = {} } = res.data
          this.isBoss = res.data.isBoss
          const nameMapping = {
            actualReceivedAmount: '实际回款金额',
            contractAmount: '合同金额',
            invoicedAmount: '开票金额',
            paymentPlanAmount: '回款计划金额',
          }
          this.contractAmountList = Object.keys(contractPaymentStatisticsVo)
            .filter(key => nameMapping.hasOwnProperty(key)) // 只处理有映射的键
            .map(key => ({
              name: nameMapping[key], // 根据键获取名称
              data: contractPaymentStatisticsVo[key],
            }))
          this.contractAmountData = contractPaymentStatisticsVo.contractPaymentListVoList || []
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    onHistogramChangeData(arr) {
      this.contractAmountData = arr
    },
    onHistogramChange(arr) {
      this.contractAmountList = arr
    },
    getType() {
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.contractAmountYear = dictVal
          this.init(dictVal)
        })
        .catch(err => {
          console.log(err)
        })
      // 获取商务负责人
      this.$api.businessManage
        .principalUserNameListNoPage()
        .then(res => {
          this.userList = res?.data.map(v => ({
            id: v.id,
            dictName: v.userName,
            dictVal: v.id,
          }))
          this.userList.unshift({
            id: '1',
            dictName: '全部',
            dictVal: '',
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
.histogramTable {
  margin-top: 10px;
  align-content: center;
  th,
  td {
    line-height: 30px;
    width: 130px;
  }
}
</style>
